$darkMode: true;
@import '~styles/colors';

.commonInput {
	border-style: none;
	background-color: $lightBlue;
	border-radius: 3px;
	white-space: nowrap;
	outline-width: 0;
	clear: right;
	padding-left: 0.25em;
	padding-right: 0.25em;
	color: $textColor;
}

.commonInput:disabled {
	opacity: 0.5;
	color: $textColor;
}

.commonInput::placeholder {
	opacity: 0.333;
	color: $textColor;
}
