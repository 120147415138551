$darkMode: true;
@import '~styles/colors';

.messageDivider:after {
	width: 75%;
	content: ' ';
	border-bottom: 1px solid grey;
}

.messageDivider {
	border-bottom: 1px solid transparent;
	border-image-source: linear-gradient(0.25turn, $backgroundColor, rgba($lightGrey, 0.75), $backgroundColor);
	border-image-slice: 1;
	height: 1px;
	width: 100%;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	display: inline-block;
}

.messagesMain > * {
	margin-bottom: 0.75em;
}

.messagesMain {
	margin-top: 0.75em;
}

.messagesView .navPreviousPage {
	text-align: left;
}

.messagesView .navNextPage {
	text-align: right;
}

.balanceTableLoading {
	opacity: 0.7;
}

.messagesWrapper {
	margin-top: 10px;
	width: 100%;
}

.navPreviousPage,
.navNextPage {
	button {
		height: 100%;
	}
	width: 20px;
	opacity: 0.75;
}

.messagesNav {
	margin-top: 0.25em;
	text-align: center;
	width: 100%;
	text-align: center;
	display: grid;
	grid-template-columns: 100px auto 100px;
}
