$darkMode: true;
@import '~styles/sizes';

.collapsingSidebarLayout {
	display: grid;
	grid-gap: 0.5em;
	grid-template-columns: 7fr 325px;
	@media screen and (max-width: $break-medium) {
		grid-template-columns: 7fr 250px;
	}
	@media screen and (max-width: $break-small) {
		grid-template-columns: 1fr;
	}
}

.collapsingSidebarLayout > :last-child {
	@media screen and (max-width: $break-small) {
		display: none;
	}
}
