$darkMode: true;
@import '~styles/sizes';
@import '~styles/colors';

.createAccountForm label {
	font-weight: bold;
}

.createAccountForm button {
	margin-top: 0.25em;
}

.createAccountFormField {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	margin-right: 5px;
	@media screen and (max-width: $break-medium) {
		width: 100%;
	}
}

.createAccountFormFieldInError {
	background-color: rgba($errorColorLight, 0.3);
}

.createAccountErrorLabel {
	color: $errorColor;
	padding-top: 0.5em;
	font-weight: bold;
}

.createAccountButton {
	margin-top: 0.25em;
}
