$darkMode: true;
@import '~styles/colors.scss';
@import '~styles/sizes.scss';

body {
	background-color: darken($backgroundColor, 5%);
	margin: 0;
	font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: $medium-font-size;
	line-height: 1.4;
	color: $textColor;
}

a:hover {
	color: $activeColor;
}

a {
	color: $primaryColor;
}

a:link {
	text-decoration: none;
}

* {
	-webkit-font-smoothing: antialiased;
}

html {
	overflow-y: scroll;
}

p {
	margin: 0.25em 0 0.75em;
}

p:last-child {
	margin: 0.25em 0 0.25em;
}

// See https://stackoverflow.com/questions/710158/why-do-my-list-item-bullets-overlap-floating-elements
ol,
ul {
	overflow: hidden;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

button,
input,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: button;
	-moz-appearance: button;
	cursor: pointer;
}

.debugDetails {
	display: none;
	font-size: 10px;
	text-align: center;
	color: grey;
	padding-top: 1em;
	border-top: 1px dotted lightgray;
	margin-left: auto;
	margin-right: auto;
	max-width: 1024px;
	border-left: 1px solid rgb(235, 235, 235);
	border-right: 1px solid rgb(235, 235, 235);
	background-color: white;
}

.skip-link {
	position: absolute;
	top: -40px;
	left: 0;
	background: #000000;
	color: white;
	padding: 8px;
	z-index: 100;
}

.skip-link:focus {
	top: 0;
}
