$darkMode: true;
@import '~styles/sizes';
@import '~styles/colors';

.loginForm > label {
	font-weight: bold;
}

.loginForm > button {
	margin-top: 0.25em;
	margin-right: 0.25em;
}

.loginControl {
	border: none;
	height: 30px;
	padding: 5px;
	max-width: 90%;
	width: 450px;
	font-size: $large-font-size;
}

.loginFormField {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	margin-right: 5px;
	@media screen and (max-width: $break-medium) {
		width: 100%;
	}
}

.loginErrorLabel {
	color: $errorColor;
	padding-top: 0.5em;
	font-weight: bold;
}

.loginButton {
	margin-top: 0.25em;
	margin-right: 0.25em;
}
