$darkMode: true;
@import '~styles/sizes';
@import '~styles/colors';

.sidebar {
	margin-left: 0.25em;
	font-size: $small-font-size;
	.slateInput {
		background-color: transparent;
	}

	.rules {
		padding-bottom: 0.5em;
		padding-top: 0.5em;
	}
}

.sidebarModerators {
	margin-bottom: 1em;
}

.subscribeButton {
	font-size: $smaller-font-size;
}

.sidebarTitle {
	font-weight: bold;
	font-size: $medium-font-size;
	padding-top: 5px;
}

.sidebarTitle p {
	margin-bottom: 5px;
}

.sidebarSubtext {
	font-size: $small-font-size;
	min-height: 1em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.sidebarSubtext li {
	margin-top: 4px;
	margin-bottom: 4px;
}

.sidebarSection {
	margin-bottom: 1em;
	.communityAge,
	.subscriberCount {
		font-size: $smaller-font-size;
	}
}
