$darkMode: true;
@import '~styles/colors';
@import '~styles/controls';
@import '~styles/sizes';
@import '~styles/card';

.cardFeedItem {
	width: 100%;
	margin-bottom: 0.25em;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	a {
		text-decoration: inherit;
		&:hover {
			text-decoration: none;
		}
	}

	@include card;
	padding-bottom: 0.25em;
}

.iframely-responsive {
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	position: relative;
	padding-bottom: 56.25%;
	border: 0 !important;
}

.iframely-responsive > * {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	border: 0 !important;
	box-shadow: none !important;
}

.iframely-responsive * {
	color: $textColor !important;
}

.cardFeedItemTitleBar {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding-bottom: 5px;

	i {
		padding-right: 3px;
	}

	.titleContainer {
		display: flex;
	}
}

.cardFeedItemTitle {
	font-size: $medium-font-size;
	color: $darkNeutral;
	font-weight: bold;
}

.cardFeedItemDetails {
	display: flex;
	flex-direction: column;
	color: $textColorLight;
	font-size: $smallest-font-size;
}

.cardFeedItemTimestamp {
	font-size: $smallest-font-size;
	color: $textColorLight;
}

.cardFeedItemDetailsRow1 {
	display: flex;
	flex-direction: row;
	margin-top: 2px;
	margin-bottom: 4px;
}

.cardFeedItemDetailsRow2 {
	color: slategray;
	font-size: $smallest-font-size;
}

.cardFeedItemValue {
	text-align: left;
	padding-right: 3px;
	font-size: $small-font-size;
	color: $textColor;
	font-weight: bold;
}
.cardFeedItemUser {
	text-align: left;
}

.cardFeedItemTimestamp {
	text-align: right;
	margin-left: 0.5em;
}

.cardFeedItemRightTools {
	margin-left: auto;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.cardFeedItemPreviewContainer {
	width: 100%;
}

.cardFeedItemTools {
	grid-row: 2 / 3;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	height: 30px;
	align-items: center;
	font-size: $smaller-font-size;
	font-weight: bold;

	> a {
		flex-grow: 1;
		text-align: center;
	}
	> div {
		flex-grow: 1;
		text-align: center;
	}

	margin-top: 6px;

	> .cardFeedItemRightTools {
		> button {
			border: none;
			color: $primaryColor;
			margin-left: 0.6em;
		}

		> button:hover {
			border: none;
			color: $activeColor;
		}
	}

	.commentValue {
		padding-left: 0.6em;
		display: flex;
		text-align: center;
		align-items: center;
	}

	.tipButton {
		color: $monetaryActionColor;
		border-radius: 3px;
	}
}

.cardFeedItemTipJar {
	vertical-align: top;
	text-align: center;
	width: 14px;
	display: inline-block;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		opacity: 0.15;
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 0.45;
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 0.15;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
		opacity: 0.15;
	}
	50% {
		transform: scale(1);
		opacity: 0.45;
	}
	100% {
		transform: scale(1);
		opacity: 0.15;
	}
}

.cardFeedItemPlaceholder {
	-webkit-animation: pulse 1s infinite ease-in-out;
	-o-animation: pulse 1s infinite ease-in-out;
	-ms-animation: pulse 1s infinite ease-in-out;
	-moz-animation: pulse 1s infinite ease-in-out;
	animation: pulse 1s infinite ease-in-out;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.cardFeedItemPlaceholder > .cardFeedItemThumbPreview {
	width: 100%;
	position: absolute;
}

.cardFeedItemImagePlaceholder {
	width: 100%;
	height: 20vh;
	background-color: lightgray;
	opacity: 0.5;
}

.cardFeedItemThumb {
	width: 100%;
	max-height: 75vh;
	padding: 0;
	object-fit: contain;
	overflow: hidden;
	display: block;
}
