$darkMode: true;
@import '~styles/colors';

.addFundsButton {
	margin-top: 0.25em;
}

.addFundsForm {
	margin-top: 0.5em;
}

.addFundsError {
	color: $errorColor;
	padding-top: 0.25em;
	font-weight: bold;
}

.addFundsSuccess {
	color: $successColor;
	padding-top: 0.25em;
	font-weight: bold;
}
