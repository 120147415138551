$darkMode: true;
@import '~styles/sizes';
@import '~styles/colors';

.balanceMainView {
	margin-top: 0.5em;
}

.balanceFundButton {
	margin-top: 0.5em;
	margin-right: 0.25em;
}

.accountBalanceContainer {
	width: 100%;
	background-color: rgba($lightGrey, 0.125);

	:first-child.balanceSection {
		border-right: 3px solid $backgroundColor;
	}

	:last-child.balanceSection {
		border-left: 3px solid $backgroundColor;
	}

	.balanceSection {
		width: 50%;
		display: inline-block;
		font-size: $largest-font-size;
		padding: 0.25em;
		text-align: center;
		vertical-align: top;

		.balance,
		.vestingBalance {
			padding: 0.125em;
		}

		.balance {
			color: $monetaryActionColor;
		}

		.vestingBalance {
			color: $vestingColor;
		}

		.balanceType {
			font-weight: bold;
		}

		.balanceLinks {
			font-size: $small-font-size;
			a {
				font-weight: bold;
			}
		}
	}
}
