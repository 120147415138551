$darkMode: true;
@import '~styles/sizes';
@import '~styles/colors';

.messageHeader {
	width: 100%;
	font-size: $smaller-font-size;
}

.messageSubHeader {
	color: $textColorLight;
	display: inline-block;
}

.messageBody {
	display: inline-block;
	margin-top: 0.125em;
	margin-bottom: 0.125em;
}

.message {
	.messageHeader {
		.messageSubject {
			font-weight: bold;
			font-size: $large-font-size;
		}
		.unreadMessageNotice,
		.deletedMessageNotice {
			font-weight: normal;
			padding-left: 4px;
			padding-right: 4px;
			border-radius: 0.25em;
			margin-left: 0.5em;
			font-size: $medium-font-size;
		}

		.unreadMessageNotice {
			background-color: rgba($primaryColor, 0.15);
		}

		.deletedMessageNotice {
			background-color: rgba($errorColor, 0.3);
		}
	}

	.replyContainer {
		margin-top: 0.5em;
	}

	background-color: $offBackgroundColor;
	box-shadow: 0.01em 0.1em 0.5em 0.2em rgba($lighterGrey, 0.75);
	border-radius: 0.35em;
	padding: 0.5em;
}

.deletedMessage {
	.messageBody,
	.messageSubject,
	.messageSubHeader,
	a,
	button {
		opacity: 0.25;

		.unreadMessageNotice,
		.deletedMessageNotice {
			opacity: 1;
		}

		pointer-events: none;
	}
}

.messageBody > :last-child {
	margin-bottom: 0;
}

.messageLinks {
	display: flex;
	flex-direction: row;
	align-content: flex-start;
	margin-top: 0.25em;
	font-weight: bold;
	font-size: $smaller-font-size;

	a,
	.textButton,
	.deleteButton {
		margin-right: 0.6em;
	}
}
