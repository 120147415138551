$darkMode: true;
@import '~styles/sizes.scss';
@import '~styles/colors.scss';

.composeMessageContainer {
	width: 100%;
	margin-bottom: 0.5em;
}

.composeMessage {
	.messageButton {
		margin-right: 0.25em;
		margin-top: 0.5em;
	}

	.messageInput {
		width: 100%;
		margin-bottom: 0.5em;
	}

	.messageSuccess {
		font-weight: bold;
		color: $successColor;
	}

	.messageFailure {
		font-weight: bold;
		color: $errorColor;
	}

	.closeButton {
		margin-left: 0.5em;
	}
}
