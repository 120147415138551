$darkMode: true;
.clockwiseRotator-enter-active {
	animation-name: rotatorKeyframe;
	animation-direction: normal;
	animation-timing-function: ease-in;
	animation-duration: var(--duration);
}
.clockwiseRotator-exit-active {
	animation-name: rotatorKeyframe;
	animation-direction: reverse;
	animation-timing-function: ease-in;
	animation-duration: var(--duration);
}
.clockwiseRotator-enter-done {
	transform: rotate(90deg);
}
.counterRotator-enter-active {
	animation-name: counterRotatorKeyframe;
	animation-direction: normal;
	animation-timing-function: ease-in;
	animation-duration: var(--duration);
}
.counterRotator-exit-active {
	animation-name: counterRotatorKeyframe;
	animation-direction: reverse;
	animation-timing-function: ease-in;
	animation-duration: var(--duration);
}
.counterRotator-enter-done {
	transform: rotate(-90deg);
}

@keyframes rotatorKeyframe {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(90deg);
	}
}

@keyframes counterRotatorKeyframe {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(-90deg);
	}
}
