$darkMode: true;
@import '~styles/colors.scss';

.loadingIndicator {
	display: block;
}

.loadingIndicator > div {
	background-color: $lightBlue;
	width: calc(100% - 0.5em);
	height: calc(100% - 0.5em);
	border-radius: 3px;
	margin: 0.25em;
}
