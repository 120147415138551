$darkMode: true;
.communityMenuItem {
	display: flex;
	flex-direction: row;
	min-height: 20px;
	align-items: center;
}
.communityIcon {
	padding: 9px;
}
.communityName {
	font-weight: 500;
}
