$darkMode: true;
@import '~styles/sizes';

.accountFormName {
	font-weight: bold;
	vertical-align: top;
	min-width: 8em;
	display: inline-block;
	margin-bottom: 0.25em;
	padding-top: 0.25em;
}

.accountFieldGrouping {
	margin-top: 0.25em;
}

.accountFormSection {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.accountFormInput {
	@media screen and (max-width: $break-medium) {
		width: 100%;
	}
}
