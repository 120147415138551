$darkMode: true;
@import '~styles/colors.scss';

.hToggle {
	display: flex;
	flex-direction: row;
}

.vToggle {
	display: flex;
	flex-direction: column;
}

.buttonToggle {
	input {
		display: none;
	}
	label {
		cursor: pointer;
		display: inline-block;
		margin-left: 2px;
		margin-right: 2px;
		padding: 0 0.25em;
		border-radius: 3px;
		background-color: rgba($lightBlue, 0.5);
		color: $primaryColor;
		outline: none !important;
		border: 1px solid $lightBlue;
	}
	input:checked + label {
		background-color: $lightBlue;
		border: 1px solid $darkerBlue;
	}
}
