$darkMode: true;
.armableButtonContainer {
	display: inline-flex;
	flex-direction: row;
}

.confirmButton {
	> svg {
		color: rgb(50, 150, 50);
	}
	&:hover {
		svg {
			color: rgb(100, 200, 100);
		}
	}
}
.disarmButton {
	svg {
		color: rgb(150, 50, 50);
	}
	&:hover {
		svg {
			color: rgb(200, 100, 100);
		}
	}
}

.armableButton {
	display: flex;
	align-items: center;
}

.armedButtonControls {
	display: flex;
	align-items: center;
	margin-right: 2px;
}

.armableButton.error {
	background-color: rgb(255, 240, 240);
}

.armableButton.error:hover {
	background-color: rgb(245, 230, 230);
}
