$darkMode: true;
@import '~styles/colors';

.filePreviewListItem {
	margin-right: 0.5em;
	margin-bottom: 0.3em;

	.imageWrapper {
		text-align: center;
	}

	img {
		transition: height 100ms linear;
		height: 125px;
	}
	position: relative;
	display: flex;
	flex-direction: column;

	.progressBarContainer {
		width: 100%;
	}
	.progressBar {
		background-color: $primaryColor;
		border-radius: 3px;
		height: 6px;
	}
}

.filePreviewListItemActive {
	img {
		height: 200px;
		transition: height 100ms linear;
	}
}
.filePreviewCaptionButton {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	&* {
		&:first-child {
			justify-self: left;
		}
		&:last-child {
			justify-self: right;
		}
	}
}

.filePreviewRemoveButton {
	position: absolute;
	top: 0;
	right: 0;
	color: rgba(150, 50, 50, 1);
	&:hover {
		color: rgba(200, 100, 100, 1);
	}
}
.filePreviewUploadButton {
	position: absolute;
	top: 0;
	left: 0;
	color: rgb(40, 150, 25);
	&:hover {
		color: rgba(80, 200, 50, 1);
	}
}

.fileCaptionEditor {
	max-width: 100%;
}
.fileCaptionEditorInput {
	width: 100%;
}
.fileCaptionEditorClose {
	width: 100%;
}
