$darkMode: true;
@import '~styles/colors';
@import '~styles/sizes';

.siteTag {
	background-color: $siteTagBackgroundColor;
}

.communityTag {
	background-color: $communityTagBackgroundColor;
}

.postTag {
	background-color: $postTagBackgroundColor;
}

.tag {
	border-radius: 0.3em;
	padding: 1px;
	font-weight: bold;
	color: white;
	font-size: $small-font-size;

	.subTag {
		background-color: white;
		color: black;
		border-top-right-radius: 0.3em;
		border-bottom-right-radius: 0.3em;
	}
}

.tag:hover {
	color: white;
	cursor: pointer;
}

.tag.editable:hover {
	cursor: default;
}

.removeTagButton {
	background-color: transparent;
	padding-left: 2px;
	padding-right: 2px;
	border: none;
	color: white;
	border-radius: 50%;
	:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}
	margin-left: 0.125em;
}
