$darkMode: true;
.communityRuleList {
	.communityRuleEditor {
		margin-bottom: 0.5em;
		margin-top: 0.25em;
	}

	> .title {
		font-weight: bold;
	}

	.rules {
		display: flex;
		flex-direction: column;
	}
}
