$darkMode: true;
@import '~styles/sizes';
@import '~styles/colors';

.postBody {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	padding: 0.4em;
	line-height: 140%;
	text-align: left;
	width: calc(100% - 0.5em);
	margin-right: 0.5em;
}

.postBody > :last-child {
	margin-bottom: 0;
}

.postHeader {
	font-size: $small-font-size;
	color: $textColorLight;
	margin-bottom: 8px;
}

.postBodyContainer {
	background-color: $offBackgroundColor;
	text-align: left;
	box-shadow: 0.01em 0.1em 0.5em 0.2em rgba($lighterGrey, 0.5);
	margin-top: 6px;
	margin-bottom: 6px;

	margin-right: 0.5em;

	@media screen and (max-width: $break-medium) {
		margin-right: 0;
	}

	.iframely-embed {
		width: 100%;
		max-width: 100% !important;
	}
}

.postLinks {
	margin-top: 4px;
	font-size: $smallest-font-size;
	font-weight: bold;

	.linkDivider {
		width: 0.6em;
		display: inline-block;
	}
	margin-bottom: 0.5em;

	.actionCost {
		color: $monetaryActionColor;
		background-color: $backgroundColor;
		border-radius: 4px;
		margin: 1px 1px;
		display: inline-block;
	}
	*:disabled {
		.actionCost {
			color: grey;
		}
	}
}

.postReplyBody {
	background-color: $lightBlue;
	max-width: 100%;
	min-height: 3em;
}

.commentViewOptionsLabel {
	display: inline-block;
	padding-right: 0.25em;
	vertical-align: top;
}

.commentViewOptionsLabel i {
	font-size: $smallest-font-size;
	color: $primaryColor;
	opacity: 0.75;
}

.commentViewOptionsSeparator {
	border-left: 1px solid $textColorVeryLight;
	height: calc(1em + 3px);
	width: 6px;
	margin: 2px 2px;
	display: inline-block;
}

.commentViewOptionsSection {
	display: flex;
	align-items: center;
	padding-bottom: 0.5em;
}

.postValueContainer {
	vertical-align: middle;
	text-align: center;
	margin-bottom: 0.25em;
}

.postValue {
	color: $tipCountColor;
}

.postImage {
	width: 100px;
}

.postImageAndText {
	padding-bottom: 0.5em;
}

.postImageContainer {
	float: left;
	display: flex;
	width: 100px;
	flex-direction: column;
	vertical-align: middle;
	margin-right: 0.75em;
	margin-bottom: 0.25em;
	box-shadow: 0.01em 0.1em 0.5em 0.2em rgba($lighterGrey, 0.5);
}

.postViewContainer {
	display: flex;
	flex-direction: column;
	padding-right: 0.5em;
	@media screen and (max-width: $break-medium) {
		padding-right: 0;
	}
}

.postViewTop {
	display: flex;
	flex-direction: column;
}

.partialCommentChainAlert {
	display: flex;
	flex-direction: row;
	padding-top: 0.125em;
	padding-bottom: 0.5em;
}

.alertIcon {
	font-size: $medium-font-size-2x;
	margin: 0.25em 0.5em 0.25em 0.35em;
}
