$darkMode: true;
@import '~styles/colors.scss';
@import '~styles/sizes.scss';
@import '~styles/card.scss';

.placeholderWidget {
	@include card;
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	height: 5em;
	width: 100%;
	font-weight: bold;
	font-size: $large-font-size;
	text-align: center;
}

.placeholderWidgetInner {
	align-items: center;
	display: flex;
	flex-grow: 1;
}
