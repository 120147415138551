$darkMode: true;
@import '~styles/sizes';
@import '~styles/colors';

.createSubViewFormSection {
	margin-top: 0.5em;
}

.createSubSingleLineInput {
	width: 250px;
	margin-bottom: 0.125em;
	display: block;
	@media screen and (max-width: $break-medium) {
		width: 100%;
	}
}

.createSubMultiLineInput {
	margin-bottom: 0.125em;
	@media screen and (max-width: $break-medium) {
		width: 100%;
	}
}

.createCommunityForm {
	max-width: 600px;

	.error {
		color: $errorColor;
		font-size: $small-font-size;
		margin-bottom: 0.125em;
	}

	.fieldDescription {
		color: $darkNeutral;
		font-size: $small-font-size;
		margin-bottom: 1em;
	}

	.required {
		font-weight: normal;
		color: $errorColorLight;
	}

	.submitButton {
		color: $monetaryActionColor;

		.actionCost {
			color: $monetaryActionColor;
			background-color: $backgroundColor;
			border-radius: 4px;
			padding: 2px 4px;
			display: inline-block;
		}
		*:disabled {
			.actionCost {
				color: grey;
			}
		}
	}

	.submitButton:disabled {
		color: $monetaryActionDisabledColor;
	}

	label {
		font-size: $medium-font-size;
		font-weight: bold;
		display: inline-block;
		margin-bottom: 0.125em;
	}
}
