$darkMode: true;
@import '~styles/safeAreaInsets';
@import '~styles/sizes';
@import '~styles/colors';

.userTopBar {
	width: 100%;
	position: sticky;
	position: -webkit-sticky;
	z-index: 2;
	top: 0;
	background-color: $backgroundColor;
	opacity: 0.95;
	font-size: $larger-font-size;
	padding: 10px 20px 5px;
	@include supports-safe-area-insets {
		//noinspection CssInvalidFunction
		padding-left: calc(20px + env(safe-area-inset-left));
		//noinspection CssInvalidFunction
		padding-right: calc(20px + env(safe-area-inset-right));
	}
	margin-left: auto;
	margin-right: auto;
	max-width: $max-site-width;
	@media screen and (max-width: $break-medium) {
		padding-left: 0.5em;
		padding-right: 0.5em;
	}

	.hasUnreadMessages {
		color: $unreadMessagesColor;
	}
}

.userTopBarBrandLink {
	display: flex;
	align-items: center;
}

.userTopBarBrand {
	height: 21px;

	@media screen and (max-width: $break-small) {
		height: 25px;
	}
}

.userTopBarHeader {
	width: 100%;
	display: flex;
}

.userTopBarHeader:before,
.userTopBarHeader:after {
	content: ' ';
	display: table;
}

.userTopBarHeader:after {
	clear: both;
}

.userTopBar:before,
.userTopBar:after {
	content: ' ';
	display: table;
}

.userTopBar:after {
	clear: both;
}

.userTopBarRight > a:hover {
	text-decoration: none;
}

.userTopBarRight {
	width: 100%;
	text-align: right;
	clear: both;
	margin-right: 3px;

	a {
		margin-left: 6px;
	}
	@media screen and (max-width: $break-small) {
		font-size: 20px;
	}

	> * {
		margin-right: 0.125em;
	}
}

.userTopBalance {
	color: $monetaryActionColor;
}

.userTopEscrowBalance {
	color: $vestingColor;
	padding: 0.125em 0.25em 0.125em 0.25em;
}

.topBarAccountBalance {
	@media screen and (max-width: $break-small) {
		display: none;
	}
}

.userTopEscrowBalanceContainer {
	@media screen and (max-width: $break-medium) {
		display: none;
	}
}

.themeToggleButton {
	outline: none;
	border: none;
	background-color: transparent;
	color: $primaryColor;
	margin-left: 6px;
	padding: 0;
}
