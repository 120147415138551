$darkMode: true;
@import '~styles/sizes';
@import '~styles/colors';

.commentChildren {
	margin-top: 0.5em;
}

.comment {
	margin-bottom: 0.5em;
	overflow: hidden;

	.linkDivider {
		width: 0.6em;
		display: inline-block;
	}

	.commentValue {
		font-weight: normal;
	}

	.truncatedUsername {
		display: none;
		@media screen and (max-width: $break-medium) {
			display: inline-block;
		}
	}

	.fullUsername {
		@media screen and (max-width: $break-medium) {
			display: none;
		}
	}
}

.commentHeader {
	font-size: $smaller-font-size;
	cursor: pointer;
}

.commentHeader:hover {
	background-color: rgba($lighterBlue, 0.65);
}

.commentExpandCollapse {
	margin-right: 2px;
	border-radius: 2px;
	outline: none;
}

.commentChildrenRight {
	flex-grow: 1;
}

.commentChildrenRight {
	.comment:first-child {
		margin-top: 0.5em;
	}
}

.commentExpandCollapse:hover {
	background-color: rgb(209, 221, 230);
	cursor: pointer;
}

.commentBody {
	margin-top: 2px;
	margin-bottom: 2px;
	line-height: 140%;
}

.commentBody.deleted {
	font-size: $smaller-font-size;
	color: rgb(100, 100, 100);
}

.commentTimestamp {
	color: darkgrey;
}

.commentLinks {
	font-size: $smallest-font-size;
	font-weight: bold;
	> *,
	> button {
		margin-right: 0.5em;
		:last-child {
			margin-right: 0;
		}
	}
	.actionCost {
		color: $monetaryActionColor;
		background-color: $backgroundColor;
		border-radius: 4px;
		padding: 1px 1px;
		display: inline-block;
	}
	*:disabled {
		.actionCost {
			color: grey;
		}
	}
}

.commentLinks a,
.commentLinks > div > a {
	cursor: pointer !important;
}

.commentLinks a:hover,
.commentLinks > div > a:hover {
	cursor: pointer !important;
}

.commentHeaderLightText {
	color: $textColorLight;
}

.collapsedComment {
	margin-bottom: 2px;
	background-color: rgba($lightGrey, 0.25);
}

.collapsedComment > .commentBody {
	display: none;
}
.collapsedComment > .commentLinks {
	display: none;
}
.collapsedComment > .commentChildren {
	display: none;
}

.commentChildren {
	display: flex;
	flex-direction: row;
}

.collapseBorder {
	border-left: 1px dotted $darkerBlue;
	margin-left: 5px;
	padding-left: 10px;
	outline: none;
}

.collapseBorder:hover {
	border-left: 3px solid $mediumBlue;
	margin-left: 4px;
	padding-left: 9px;
	cursor: pointer;
}
