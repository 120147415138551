$darkMode: true;
@import '~styles/safeAreaInsets';
@import '~styles/sizes';
@import '~styles/colors';

.mainContent {
	padding-top: 0;
	min-height: calc(100vh - 8em);
	padding-left: 20px;
	padding-right: 20px;
	@include supports-safe-area-insets {
		//noinspection CssInvalidFunction
		padding-left: calc(20px + env(safe-area-inset-left));
		//noinspection CssInvalidFunction
		padding-right: calc(20px + env(safe-area-inset-right));
	}
	@media screen and (max-width: $break-medium) {
		padding-left: 0.5em;
		padding-right: 0.5em;
	}
}

.dynamicallyPaddedContainer {
	margin: auto;
	max-width: $max-site-width;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	background-color: $backgroundColor;
}

.mainContent > div > .nav > li > a {
	padding: 3px 8px !important;
}

.innerFooter {
	margin: auto;
	max-width: $max-site-width;
	padding: 1em 1.25em 1.25em;
	text-align: center;
	font-size: $smallest-font-size;
	background-color: $backgroundColor;
}

.footerDivider:after {
	width: 75%;
	content: ' ';
	border-bottom: 1px solid grey;
}

.footerDivider {
	border-bottom: 1px dotted rgba(197, 197, 197, 0.5);
	height: 1px;
	width: 70%;
	margin: 6px 15% 5px;
	display: inline-block;
}
