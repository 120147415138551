$darkMode: true;
@import '~styles/colors';
@import '~styles/sizes';

.tagPicker {
	display: inline-block;
}

.tagSectionTags > .tag {
	padding-left: 0.25em;
	padding-right: 0.25em;
}

.tagPicker > .tag {
	margin-right: 0.25em;
	padding-left: 0.25em;
	padding-right: 0.25em;
}

.tagPickerLabel {
	font-weight: bold;
}

.standardButton.addTagButton {
	margin-right: 0.5em;
}
